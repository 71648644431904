export const TRIGGERS_BB_ECONOMIC_CREATE_API_URL = '/triggers/building-blocks/economic/'; // POST
export const TRIGGERS_IS_ECONOMIC_CREATE_API_URL = '/triggers/invest-strategies/economic/'; // POST
export const TRIGGERS_DELETE_API_URL = (triggerId: string) => `/triggers/${triggerId}`; // POST
export const TRIGGERS_GET_LIST_API_URL = '/triggers/'; // GET
export const TRIGGERS_SMART_CONTRACTS_CREATE_API_URL = '/triggers/smart-contracts/'; // POST
export const TRIGGERS_SMART_CONTRACTS_FUNCTION_CREATE_API_URL = '/triggers/smart-contracts/functions'; // POST
export const TRIGGERS_SMART_CONTRACTS_GET_TRIGGER_API_URL = (
  contractId: string,
  triggerId: string,
) => `/triggers/smart-contracts/${contractId}/trigger/${triggerId}`; // GET
export const TRIGGERS_SMART_CONTRACTS_TRIGGER_UPDATE_API_URL = (
  contractId: string,
  triggerId: string,
) => `/triggers/smart-contracts/${contractId}?trigger_id=${triggerId}`; // PUT
export const TRIGGERS_SMART_CONTRACTS_TRIGGER_FUNCTION_UPDATE_API_URL = (
  functionId: string,
  triggerId: string,
) => `/triggers/smart-contracts/functions/${functionId}?trigger_id=${triggerId}`; // PUT
export const TRIGGERS_GET_TRIGGER_BY_ID_API_URL = (triggerId: string) => `/triggers/${triggerId}`; // GET
export const TRIGGERS_TRIGGER_INFO_API_URL = '/trigger-info'; // GET, params id ts
