import { createSlice } from '@reduxjs/toolkit';
import { getSmartContractAudit } from '../services/getSmartContractAudit';
import { SmartContractAuditSchema } from '../types/SmartContractAuditSchema';
import { deleteSmartContact } from '../services/deleteSmartContract';

const initialState: SmartContractAuditSchema = {
  isLoading: false,
};

const SmartContractAuditPageSlice = createSlice({
  name: 'SmartContractAuditPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

    // getSmartContractAudit

      .addCase(getSmartContractAudit.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(getSmartContractAudit.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      })
      .addCase(getSmartContractAudit.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })

    // deleteSmartContract

      .addCase(deleteSmartContact.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })

      .addCase(deleteSmartContact.fulfilled, (state) => {
        state.isLoading = false;
      })

      .addCase(deleteSmartContact.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
});

export const { actions: SmartContractAuditPageActions } = SmartContractAuditPageSlice;
export const { reducer: SmartContractAuditPageReducer } = SmartContractAuditPageSlice;
