import { createSlice } from '@reduxjs/toolkit';
import { TriggersSchema } from '../types/triggersSchema';
import { getTriggers } from '../services/getTriggers';

const initialState: TriggersSchema = {
  isLoading: false,
};

const triggersSlice = createSlice({
  name: 'triggers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

    // getTriggers

      .addCase(getTriggers.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(getTriggers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      })
      .addCase(getTriggers.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
});

export const { actions: triggersActions } = triggersSlice;
export const { reducer: triggersReducer } = triggersSlice;
