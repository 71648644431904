import { memo, useMemo } from 'react';
import { motion } from 'framer-motion';
import SuccessIcon from 'shared/assets/icons/ui/success.svg';
import WarningIcon from 'shared/assets/icons/ui/info.svg';
import ErrorIcon from 'shared/assets/icons/ui/error.svg';
import NotificationIcon from 'shared/assets/icons/ui/notification.svg';
import CloseIcon from 'shared/assets/icons/ui/close.svg';
import { classNames } from 'shared/lib/classNames';
import cls from './Notification.module.scss';

export enum NotificationStatuses {
  error = 'error',
  notification = 'notification',
  success = 'success',
  warning = 'warning'
}

interface NotificationProps {
  className?: string;
  type?: NotificationStatuses;
  title?: string;
  description?: string;
  links?: {
    title: string;
    to: string;
  }[];
  close?: () => void;
}

export const Notification = memo(
  (props : NotificationProps) => {
    const {
      className,
      type = NotificationStatuses.notification,
      title,
      description,
      links,
      close,
    } = props;

    const statusIcon = useMemo(() => {
      switch (type) {
      case NotificationStatuses.success:
        return <SuccessIcon />;
      case NotificationStatuses.error:
        return <ErrorIcon />;
      case NotificationStatuses.warning:
        return <WarningIcon />;
      case NotificationStatuses.notification:
        return <NotificationIcon />;
      default: return <NotificationIcon />;
      }
    }, [type]);

    return (
      <motion.div
        className={classNames(cls.Notification, {}, [className, cls[type]])}
        whileHover={{
          scale: 1.02,
          transition: {
            duration: 0.3,
          },
        }}
      >
        <div className={cls.status}>
          {statusIcon}
        </div>
        <div className={cls.body}>
          <div className={cls.text}>
            <p className={cls.title}>
              {title}
            </p>
            {description && (
              <span className={cls.description}>
                {description}
              </span>
            )}
          </div>
          {links?.map((link) => (
            <a
              href={link.to}
              className={cls.link}
              key={link.to + link.title}
              target="_blank"
              rel="noreferrer"
            >
              {link.title}
            </a>
          ))}
        </div>
        <motion.button
          type="button"
          className={cls.close}
          onClick={close}
          whileHover={{
            scale: 1.2,
            transition: {
              duration: 0.3,
            },
          }}
          whileTap={{ scale: 0.9 }}
        >
          <CloseIcon
            className={cls.closeIcon}
          />
        </motion.button>
      </motion.div>
    );
  },
);
