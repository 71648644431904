import { memo } from 'react';
import ShiftSmallLogo from 'shared/assets/icons/media/shift-small.svg';
import ShiftTextLogo from 'shared/assets/icons/media/shift-text.svg';
import Backoffice from 'shared/assets/icons/media/shift-backoffice.svg';
import cls from './Sidebar.module.scss';

export const Header = memo(() => (
  <div className={cls.title}>
    <div className={cls.logo}>
      <span className={cls.logoIcon}>
        <ShiftSmallLogo />
      </span>
      <span className={cls.logoText}>
        <ShiftTextLogo />
      </span>
      <Backoffice className={cls.logoBackoffice} />
    </div>
  </div>
));
