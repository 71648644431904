export enum LOCAL_STORAGE_KEYS {
  ACCOUNTS_LIST_ID = 'accountsList',
  APP_THEME = 'app_theme',
  ASSETS_LIST_ID = 'assetsList',
  BLOCKCHAINS_LIST_ID = 'blockchainsList',
  BRIDGES_LIST_ID = 'bridgesList',
  CLIENTS_LIST_ID = 'clientsList',
  DECURITY_IDS_ID = 'decurityIDs',
  DEFIIS_LIST_ID = 'defiisList',
  FACTORIES_LIST_ID = 'factoriesList',
  LENDINGS_LIST_ID = 'lendingsList',
  PLATFORMS_LIST_ID = 'platformsList',
  PROTOCOLS_LIST_ID = 'protocolsList',
  SIDEBAR_IS_OPEN = 'sidebar_is_open',
  SWAPS_LIST_ID= 'swapsList',
  TRIGGERS_LIST_ID = 'triggersList',
  UTILITIES_LIST_ID = 'utilitiesList',
  VAULTS_LIST_ID = 'vaultsList'
}
