import {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Variants, motion } from 'framer-motion';
import {
  Accordion, AccordionDetails, Tooltip,
} from '@mui/material';
import { AppRoutesProps } from 'shared/configs/routes/routeConfig';
import { LinkWrapper } from './LinkWrapper';
import { TooltipStyles } from '../config/sidebarTooltipStyle';
import { containerLinksVar, linkVar } from '../config/animations';
import cls from './Sidebar.module.scss';
import { NavigateLink } from './NavigateLink';
import { NestedLinksSummary } from './NestedLinksSummary';

interface NestedLinksProps {
  nestedLinks: AppRoutesProps[];
  path: AppRoutesProps['path'];
  title: AppRoutesProps['title'];
  icon: AppRoutesProps['icon'];
  sidebarOpen: boolean;
  isOutletLink?: boolean;
  openSidebar: () => void;
}

export const NestedLinks = memo((props : NestedLinksProps) => {
  const {
    nestedLinks, path, title, icon, sidebarOpen, isOutletLink, openSidebar,
  } = props;

  const [expanded, setExpanded] = useState(false);

  const openNestedPages = useCallback(() => {
    setExpanded((prev) => !prev);
    if (!sidebarOpen) {
      openSidebar();
    }
  }, [sidebarOpen, openSidebar]);
  const linkWrapper = useMemo(() => (isOutletLink ? `${path}/` : ''), [isOutletLink, path]);

  useEffect(() => { if (!sidebarOpen) setExpanded(false); }, [sidebarOpen]);

  const stokeLine:Variants = useMemo(() => (
    {
      hidden: {
        height: '0%',
        opacity: 0,
        transition: {
          duration: 0,
          delay: 0,
        },
      },
      visible: {
        height: 'auto',
        opacity: 1,
        transition: {
          duration: 0.5,
          delay: nestedLinks.length * 0.1,
          type: 'tween',
        },
      },
    }
  ), [nestedLinks]);

  return (
    <Tooltip
      key={path}
      title={sidebarOpen ? '' : title}
      placement="right"
      componentsProps={TooltipStyles}
    >
      <Accordion
        key={path}
        className={cls.navigationItem}
        disableGutters
        sx={{ '&:before': { display: 'none' } }}
        expanded={expanded}
        onChange={openNestedPages}
      >
        <LinkWrapper>
          <NestedLinksSummary
            path={path}
            icon={icon}
            title={title}
          />
        </LinkWrapper>
        <AccordionDetails
          sx={{ padding: '10px 10px 0px' }}
          className={cls.nestedLinksContainer}
        >
          <motion.div
            initial="hidden"
            variants={stokeLine}
            className={cls.strokeLine}
            animate={expanded ? 'visible' : 'hidden'}
          />
          <motion.div
            variants={containerLinksVar}
            className={cls.nestedPages}
            initial="hidden"
            animate={expanded ? 'visible' : 'hidden'}
          >
            {nestedLinks
              .sort((a, b) => (a.title > b.title ? 1 : -1))
              .map((nestedPage) => (
                <LinkWrapper
                  variants={linkVar}
                  className={cls.navigationItem}
                  key={nestedPage.path}
                >
                  <NavigateLink
                    icon={nestedPage.icon}
                    title={nestedPage.title}
                    className={cls.nestedLink}
                    path={`${linkWrapper}${nestedPage.path}`}
                  />
                </LinkWrapper>
              ))}
          </motion.div>
        </AccordionDetails>
      </Accordion>
    </Tooltip>
  );
});
