import { memo } from 'react';
import { motion } from 'framer-motion';
import { classNames } from 'shared/lib/classNames';
import { Skeleton } from '@mui/material';
import { blockLoaderVar } from 'shared/configs/animations/blockLoader';
import { PageTitleLoader } from 'shared/ui/PageTitle';
import cls from './TableBlock.module.scss';

interface TableBlockLoaderProps {
  className?: string;
}

export const TableBlockLoader = memo((props : TableBlockLoaderProps) => {
  const { className } = props;
  return (
    <div className={classNames(cls.TableBlock, {}, [className])}>
      <PageTitleLoader />
      <motion.div
        initial="hidden"
        animate="visible"
        className={cls.details}
        variants={blockLoaderVar}
      >
        <Skeleton
          variant="rectangular"
          sx={{ borderRadius: 'var(--border-radius-s)' }}
          height="100%"
        />
      </motion.div>
    </div>
  );
});
