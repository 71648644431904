import {
  ReactNode, useMemo, useRef,
} from 'react';
import { HistoryContext } from './HistoryContext';

export const HistoryProvider = ({ children } : { children: ReactNode }) => {
  const previousLocationPathname = useRef<string>('');
  const context = useMemo(() => ({ previousLocationPathname }), []);

  return (
    <HistoryContext.Provider value={context}>
      {children}
    </HistoryContext.Provider>
  );
};
