import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import ModalMUI from '@mui/material/Modal';
import { classNames } from 'shared/lib/classNames';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import cls from './Modal.module.scss';

interface ModalProps {
  className?: string;
  open: boolean;
  onClose: () => void;
  footer?: ReactNode;
  children?: ReactNode;
  header?: string;
  width?: string;
}

export const Modal = (props : ModalProps) => {
  const {
    className,
    open,
    onClose,
    header,
    footer,
    width,
    children,
  } = props;

  return (
    <ModalMUI
      open={open}
      onClose={onClose}
      style={{ outline: 'none' }}
    >
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            opacity: 0,
          },
          visible: {
            opacity: 1,
            transition: {
              duration: 0.3,
            },
          },
        }}
        style={{ width }}
        className={classNames(cls.Modal, {}, [className])}
      >
        <div className={cls.closeBtn}>
          <IconButton
            size="small"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {
          header && (
            <div className={cls.header}>
              {header}
            </div>
          )
        }
        {children && (
          <div className={cls.body}>
            {children}
          </div>
        )}
        {
          footer && (
            <div className={cls.footer}>
              {footer}
            </div>
          )
        }
      </motion.div>
    </ModalMUI>
  );
};
