/* eslint-disable max-len */
import { RouteProps } from 'react-router-dom';

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleIcon from '@mui/icons-material/People';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';

import { NotFoundPage } from 'pages/Common/NotFoundPage';
import { DefiiPage } from 'pages/InvestmentStrategies/Defiis/DefiiPage';
import { DefiisListPage } from 'pages/InvestmentStrategies/Defiis/DefiisListPage';
import { AuthPage } from 'pages/Common/AuthPage';
import { BlockchainsListPage } from 'pages/BuildingBlocks/Blockchain/BlockchainsListPage';
import { ProtocolsListPage } from 'pages/BuildingBlocks/Protocol/ProtocolsListPage';
import { AssetsListPage } from 'pages/BuildingBlocks/Asset/AssetsListPage';
import { UtilitiesListPage } from 'pages/BuildingBlocks/Utility/UtilitiesListPage';
import { BlockchainPage } from 'pages/BuildingBlocks/Blockchain/BlockchainPage';
import { UtilityPage } from 'pages/BuildingBlocks/Utility/UtilityPage';
import { AssetPage } from 'pages/BuildingBlocks/Asset/AssetPage';
import { PlatformsPage } from 'pages/BuildingBlocks/Platform/PlatformsListPage';
import { PlatformPage } from 'pages/BuildingBlocks/Platform/PlatformPage';
import { EconomicAudit } from 'pages/EconomicAudit';
import { TriggerPage } from 'pages/TriggerPage';
import { ProtocolPage } from 'pages/BuildingBlocks/Protocol/ProtocolPage';
import { ITauditPage } from 'pages/ITauditPage';
import { SmartContractAuditPage } from 'pages/SmartContractAuditPage';
import { AccountingPage, MarketPerformancePage, RiskManagementPage } from 'pages/Dashboards';
import { SmartContactAuditReportPage } from 'pages/SmartContactAuditReportPage';
import { TriggersPage } from 'pages/TriggersPage';
import { RevenueTokensListPage } from 'pages/BuildingBlocks/RevenueTokens/RevenueTokensListPage';
import { RevenueTokenPage } from 'pages/BuildingBlocks/RevenueTokens/RevenueTokenPage';
import { VaultsListPage } from 'pages/VaultsListPage';
import { VaultPage } from 'pages/VaultPage';
import { AccountsListPage } from 'pages/AccountsListPage';
import { ClientsListPage } from 'pages/ClientsListPage';
import { AccountPage } from 'pages/AccountPage';
import { ClientPage } from 'pages/ClientPage';
import { LendingsListPage } from 'pages/InvestmentStrategies/Lendings/LendingsListPage';
import { LendingPage } from 'pages/InvestmentStrategies/Lendings/LendingPage';
import { ExecutionPage } from 'pages/Execution/ExecutionPage';
import { SmartContractInterfacePage } from 'pages/Execution/SmartContractInterfacePage';
import { SwapTokensPage } from 'pages/Execution/SwapTokensPage';
import { TradingInterfacePage } from 'pages/Execution/TradingInterfacePage';
import { DecurityIdsPage } from 'pages/DecurityIdsPage';
import { SwapsAndBridgesPage } from 'pages/InvestmentStrategies/SwapsAndBridgesPage';
import { SwapPage } from 'pages/InvestmentStrategies/SwapPage';
import { BridgePage } from 'pages/InvestmentStrategies/BridgePage';
import {
  EntityManagerPage, HRLendingManagerPage, PlatformManagerPage,
} from 'pages/ALTMangerPage';
import { WhitelistingPage } from 'pages/WhitelistingPage';
import { VerificationAddressesPage } from 'pages/VerificationAddressesPage';
import { FactoriesListPage } from 'pages/InvestmentStrategies/FactoriesPage/FactoriesListPage';
import { FactoryPage } from 'pages/InvestmentStrategies/FactoriesPage/FactoryPage';
import WhitelistingIcon from 'shared/assets/icons/ui/whitelisting.svg';
import VaultIcon from 'shared/assets/icons/ui/vault.svg';

import { NestedPagesParams } from './pagesParams';

export enum AppRoutes {
  ACCOUNT = 'account',
  ACCOUNTING = 'accounting',
  ACCOUNTS = 'accounts',
  ALT_MANAGER = 'alt_manager',
  ASSET = 'asset',
  ASSETS = 'assets',
  AUTH = 'login',
  BLOCKCHAIN = 'blockchain',
  BLOCKCHAINS = 'blockchains',
  BRIDGES = 'bridges',
  BUILDING_BLOCKS = 'building_blocks',
  CLIENT = 'client',
  CLIENTS = 'clients',
  CRITICAL_FUNCTION_RED_FLAGS = 'cri_fun_rf',
  DASHBOARD = 'dashboard',
  DECURITY_IDS = 'decurity_ids',
  DEFII = 'defii',
  DEFIIS = 'defiis',
  ECONOMIC_AUDIT = 'economic_audit',
  ECONOMIC_TRIGGER = 'economic_trigger',
  EXECUTION = 'execution',
  EXECUTION_SWAP_TOKENS = 'swap_tokens',
  EXECUTION_TRADING_INTERFACE = 'trading_interface',
  HR_LENDING = 'hr_lending',
  INVESTMENT_STRATEGIES = 'investment_strategies',
  IS_FACTORIES = 'is_factories',
  IS_FACTORY = 'is_factory',
  IT_AUDIT = 'it_audit',
  LENDING = 'lending',
  LENDINGS = 'lendings',
  MARKET_PERFORMANCE = 'market_performance',
  NOT_FOUND = 'not_found',
  PLATFORM = 'platform',
  PLATFORMS = 'platforms',
  PROTOCOL = 'protocol',
  PROTOCOLS = 'protocols',
  RAL_ENTITY = 'ral_entity',
  RAL_PLATFORM = 'ral_platform',
  REVENUE_TOKEN = 'revenue_token',
  REVENUE_TOKENS = 'blockchain_accounting',
  RISK_MANAGEMENT = 'risk_management',
  SERVICE_FUNCTION_RED_FLAGS = 'ser_fun_rf',
  SMART_CONTRACTS = 'smart_contracts',
  SMART_CONTRACT_AUDIT_REPORT = 'smart_contract_audit_report',
  SMART_CONTRACT_INTERFACE = 'sci',
  SMART_CONTRACT_TRIGGER = 'smart_contract_trigger',
  SWAPS = 'swaps',
  SWAPS_AND_BRIDGES = 'swaps_and_bridges',
  TRIGGER = 'trigger',
  TRIGGERS = 'triggers',
  UTILITIES = 'utilities',
  UTILITY = 'utility',
  VAULT = 'vault',
  VAULTS = 'vaults',
  WHITELISTING = 'whitelisting',
  WHITELISTING_CONTROL = 'control',
  WHITELISTING_VERIFICATION_ADDRESSES = 'verification_addresses',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.ALT_MANAGER]: `/${AppRoutes.ALT_MANAGER}`,
  [AppRoutes.RAL_ENTITY]: `/${AppRoutes.ALT_MANAGER}/${AppRoutes.RAL_ENTITY}`,
  [AppRoutes.RAL_PLATFORM]: `/${AppRoutes.ALT_MANAGER}/${AppRoutes.RAL_PLATFORM}`,
  [AppRoutes.HR_LENDING]: `/${AppRoutes.ALT_MANAGER}/${AppRoutes.HR_LENDING}`,

  [AppRoutes.DASHBOARD]: `/${AppRoutes.DASHBOARD}`,
  [AppRoutes.ACCOUNTING]: `/${AppRoutes.DASHBOARD}/${AppRoutes.ACCOUNTING}`,
  [AppRoutes.MARKET_PERFORMANCE]: `/${AppRoutes.DASHBOARD}/${AppRoutes.MARKET_PERFORMANCE}`,
  [AppRoutes.RISK_MANAGEMENT]: `/${AppRoutes.DASHBOARD}/${AppRoutes.RISK_MANAGEMENT}`,

  [AppRoutes.INVESTMENT_STRATEGIES]: `/${AppRoutes.INVESTMENT_STRATEGIES}`,
  [AppRoutes.BUILDING_BLOCKS]: `/${AppRoutes.BUILDING_BLOCKS}`,
  [AppRoutes.TRIGGERS]: `/${AppRoutes.TRIGGERS}`,
  [AppRoutes.TRIGGER]: `/${AppRoutes.TRIGGERS}/:${NestedPagesParams.ID}`,
  [AppRoutes.VAULTS]: `/${AppRoutes.VAULTS}`,
  [AppRoutes.VAULT]: `/${AppRoutes.VAULTS}/:${NestedPagesParams.ID}`,

  [AppRoutes.BLOCKCHAINS]: `/${AppRoutes.BUILDING_BLOCKS}/${AppRoutes.BLOCKCHAINS}`,
  [AppRoutes.BLOCKCHAIN]: `/${AppRoutes.BUILDING_BLOCKS}/${AppRoutes.BLOCKCHAINS}/:${NestedPagesParams.ID}`,

  [AppRoutes.UTILITIES]: `/${AppRoutes.BUILDING_BLOCKS}/${AppRoutes.UTILITIES}`,
  [AppRoutes.UTILITY]: `/${AppRoutes.BUILDING_BLOCKS}/${AppRoutes.UTILITIES}/:${NestedPagesParams.ID}`,

  [AppRoutes.ASSETS]: `/${AppRoutes.BUILDING_BLOCKS}/${AppRoutes.ASSETS}`,
  [AppRoutes.ASSET]: `/${AppRoutes.BUILDING_BLOCKS}/${AppRoutes.ASSETS}/:${NestedPagesParams.ID}`,

  [AppRoutes.PLATFORMS]: `/${AppRoutes.BUILDING_BLOCKS}/${AppRoutes.PLATFORMS}`,
  [AppRoutes.PLATFORM]: `/${AppRoutes.BUILDING_BLOCKS}/${AppRoutes.PLATFORMS}/:${NestedPagesParams.ID}`,

  [AppRoutes.PROTOCOLS]: `/${AppRoutes.BUILDING_BLOCKS}/${AppRoutes.PROTOCOLS}`,
  [AppRoutes.PROTOCOL]: `/${AppRoutes.BUILDING_BLOCKS}/${AppRoutes.PROTOCOLS}/:${NestedPagesParams.ID}`,

  [AppRoutes.REVENUE_TOKENS]: `/${AppRoutes.BUILDING_BLOCKS}/${AppRoutes.REVENUE_TOKENS}`,
  [AppRoutes.REVENUE_TOKEN]: `/${AppRoutes.BUILDING_BLOCKS}/${AppRoutes.REVENUE_TOKENS}/:${NestedPagesParams.ID}`,

  [AppRoutes.DEFIIS]: `/${AppRoutes.INVESTMENT_STRATEGIES}/${AppRoutes.DEFIIS}`,
  [AppRoutes.DEFII]: `/${AppRoutes.INVESTMENT_STRATEGIES}/${AppRoutes.DEFIIS}/:${NestedPagesParams.ID}`,

  [AppRoutes.LENDINGS]: `/${AppRoutes.INVESTMENT_STRATEGIES}/${AppRoutes.LENDINGS}`,
  [AppRoutes.LENDING]: `/${AppRoutes.INVESTMENT_STRATEGIES}/${AppRoutes.LENDINGS}/:${NestedPagesParams.ID}`,

  [AppRoutes.SWAPS_AND_BRIDGES]: `/${AppRoutes.INVESTMENT_STRATEGIES}/${AppRoutes.SWAPS_AND_BRIDGES}/`,
  [AppRoutes.SWAPS]: `/${AppRoutes.INVESTMENT_STRATEGIES}/${AppRoutes.SWAPS_AND_BRIDGES}/${AppRoutes.SWAPS}/:${NestedPagesParams.ID}`,
  [AppRoutes.BRIDGES]: `/${AppRoutes.INVESTMENT_STRATEGIES}/${AppRoutes.SWAPS_AND_BRIDGES}/${AppRoutes.BRIDGES}/:${NestedPagesParams.ID}`,

  [AppRoutes.IS_FACTORIES]: `/${AppRoutes.INVESTMENT_STRATEGIES}/${AppRoutes.IS_FACTORIES}`,
  [AppRoutes.IS_FACTORY]: `/${AppRoutes.INVESTMENT_STRATEGIES}/${AppRoutes.IS_FACTORIES}/:${NestedPagesParams.ID}`,

  [AppRoutes.ACCOUNTS]: `/${AppRoutes.ACCOUNTS}`,
  [AppRoutes.ACCOUNT]: `/${AppRoutes.ACCOUNTS}/:${NestedPagesParams.ID}`,

  [AppRoutes.CLIENTS]: `/${AppRoutes.CLIENTS}`,
  [AppRoutes.CLIENT]: `/${AppRoutes.CLIENTS}/:${NestedPagesParams.ID}`,

  [AppRoutes.WHITELISTING]: `/${AppRoutes.WHITELISTING}`,
  [AppRoutes.WHITELISTING_CONTROL]: `/${AppRoutes.WHITELISTING}/${AppRoutes.WHITELISTING_CONTROL}`,
  [AppRoutes.WHITELISTING_VERIFICATION_ADDRESSES]: `/${AppRoutes.WHITELISTING}/${AppRoutes.WHITELISTING_VERIFICATION_ADDRESSES}`,
  [AppRoutes.DECURITY_IDS]: `/${AppRoutes.DECURITY_IDS}`,

  // EXECUTION
  [AppRoutes.EXECUTION]: `/${AppRoutes.EXECUTION}`,
  [AppRoutes.SMART_CONTRACT_INTERFACE]: `${AppRoutes.SMART_CONTRACT_INTERFACE}`,
  [AppRoutes.EXECUTION_SWAP_TOKENS]: `${AppRoutes.EXECUTION_SWAP_TOKENS}`,
  [AppRoutes.EXECUTION_TRADING_INTERFACE]: `${AppRoutes.EXECUTION_TRADING_INTERFACE}`,

  // ECONOMIC AUDIT
  [AppRoutes.ECONOMIC_AUDIT]: `/:${NestedPagesParams.SECTION}/:${NestedPagesParams.ENTITIES}/:${NestedPagesParams.ENTITIES_ID}/${AppRoutes.ECONOMIC_AUDIT}`,
  [AppRoutes.ECONOMIC_TRIGGER]: `/:${NestedPagesParams.SECTION}/:${NestedPagesParams.ENTITIES}/:${NestedPagesParams.ENTITIES_ID}/${AppRoutes.ECONOMIC_AUDIT}/${AppRoutes.ECONOMIC_TRIGGER}/:${NestedPagesParams.ID}`,

  // IT AUDIT
  [AppRoutes.IT_AUDIT]: `/:${NestedPagesParams.SECTION}/:${NestedPagesParams.ENTITIES}/:${NestedPagesParams.ENTITIES_ID}/${AppRoutes.IT_AUDIT}`,
  [AppRoutes.SMART_CONTRACTS]: `/${AppRoutes.SMART_CONTRACTS}/:${NestedPagesParams.ID}`,
  [AppRoutes.SMART_CONTRACT_TRIGGER]: `/:${NestedPagesParams.SECTION}/:${NestedPagesParams.ENTITIES}/:${NestedPagesParams.ENTITIES_ID}/${AppRoutes.IT_AUDIT}/${AppRoutes.SMART_CONTRACTS}/:${NestedPagesParams.NESTED_ID}/${AppRoutes.SMART_CONTRACT_TRIGGER}/:${NestedPagesParams.ID}`,
  [AppRoutes.SMART_CONTRACT_AUDIT_REPORT]: `/${AppRoutes.SMART_CONTRACT_AUDIT_REPORT}`,

  [AppRoutes.AUTH]: `${AppRoutes.AUTH}`,
  [AppRoutes.NOT_FOUND]: '*',

  [AppRoutes.CRITICAL_FUNCTION_RED_FLAGS]: `/${AppRoutes.SMART_CONTRACTS}/:${NestedPagesParams.ENTITIES_ID}/${AppRoutes.CRITICAL_FUNCTION_RED_FLAGS}/:${NestedPagesParams.ID}`,
  [AppRoutes.SERVICE_FUNCTION_RED_FLAGS]: `/${AppRoutes.SMART_CONTRACTS}/:${NestedPagesParams.ENTITIES_ID}/${AppRoutes.SERVICE_FUNCTION_RED_FLAGS}/:${NestedPagesParams.ID}`,

};

export type AppRoutesProps = RouteProps & {
  title: string;
  path: string;
  navigate?: boolean;
  icon?: JSX.Element;
  nestedPages?: Partial<Record<AppRoutes, AppRoutesProps>>;
  outletPages?: Partial<Record<AppRoutes, AppRoutesProps>>;
}

export const routeConfig: Partial<Record<AppRoutes, AppRoutesProps>> = {

  // NAVIGATION

  [AppRoutes.DASHBOARD]: {
    title: 'Dashboards',
    path: RoutePath.dashboard,
    navigate: true,
    icon: <DashboardOutlinedIcon />,
    nestedPages: {
      [AppRoutes.MARKET_PERFORMANCE]: {
        title: 'Market performance',
        path: RoutePath.market_performance,
        element: <MarketPerformancePage />,
      },
      [AppRoutes.ACCOUNTING]: {
        title: 'Accounting',
        path: RoutePath.accounting,
        element: <AccountingPage />,
      },
      [AppRoutes.RISK_MANAGEMENT]: {
        title: 'Risk management',
        path: RoutePath.risk_management,
        element: <RiskManagementPage />,
      },
    },
  },

  [AppRoutes.INVESTMENT_STRATEGIES]: {
    title: 'Investment Strategies',
    path: RoutePath.investment_strategies,
    navigate: true,
    icon: <MonetizationOnIcon />,
    nestedPages: {
      [AppRoutes.DEFIIS]: {
        title: 'DeFiis',
        path: RoutePath.defiis,
        element: <DefiisListPage />,
      },
      [AppRoutes.LENDINGS]: {
        title: 'Lendings',
        path: RoutePath.lendings,
        element: <LendingsListPage />,
      },
      [AppRoutes.SWAPS_AND_BRIDGES]: {
        title: 'Swaps & Bridges',
        path: RoutePath.swaps_and_bridges,
        element: <SwapsAndBridgesPage />,
      },
      [AppRoutes.IS_FACTORIES]: {
        title: 'Factories',
        path: RoutePath.is_factories,
        element: <FactoriesListPage />,
      },
    },
  },

  [AppRoutes.BUILDING_BLOCKS]: {
    title: 'Building Blocks',
    path: RoutePath.building_blocks,
    navigate: true,
    icon: <WidgetsOutlinedIcon />,
    nestedPages: {
      [AppRoutes.BLOCKCHAINS]: {
        title: 'Blockchains',
        path: RoutePath.blockchains,
        element: <BlockchainsListPage />,
      },
      [AppRoutes.PROTOCOLS]: {
        title: 'Protocols',
        path: RoutePath.protocols,
        element: <ProtocolsListPage />,
      },
      [AppRoutes.ASSETS]: {
        title: 'Assets',
        path: RoutePath.assets,
        element: <AssetsListPage />,
      },
      [AppRoutes.PLATFORMS]: {
        title: 'Platforms',
        path: RoutePath.platforms,
        element: <PlatformsPage />,
      },
      [AppRoutes.UTILITIES]: {
        title: 'Utilities',
        path: RoutePath.utilities,
        element: <UtilitiesListPage />,
      },
      [AppRoutes.REVENUE_TOKENS]: {
        title: 'Blockchain accounting',
        path: RoutePath.blockchain_accounting,
        element: <RevenueTokensListPage />,
      },
    },
  },

  [AppRoutes.DECURITY_IDS]: {
    title: 'Decurity IDs',
    path: RoutePath.decurity_ids,
    element: <DecurityIdsPage />,
    icon: <SecurityIcon />,
    navigate: true,
  },

  [AppRoutes.TRIGGERS]: {
    title: 'Triggers',
    path: RoutePath.triggers,
    element: <TriggersPage />,
    icon: <CrisisAlertIcon />,
    navigate: true,
  },
  [AppRoutes.VAULTS]: {
    title: 'Vaults',
    path: RoutePath.vaults,
    element: <VaultsListPage />,
    icon: <VaultIcon />,
    navigate: true,
  },
  [AppRoutes.ACCOUNTS]: {
    title: 'Accounts',
    path: RoutePath.accounts,
    element: <AccountsListPage />,
    icon: <ManageAccountsIcon />,
    navigate: true,
  },
  [AppRoutes.CLIENTS]: {
    title: 'Clients',
    path: RoutePath.clients,
    element: <ClientsListPage />,
    icon: <PeopleIcon />,
    navigate: true,
  },
  [AppRoutes.ALT_MANAGER]: {
    title: 'ALT manager',
    path: RoutePath.alt_manager,
    icon: <SpeedIcon />,
    navigate: true,
    nestedPages: {
      [AppRoutes.RAL_ENTITY]: {
        title: 'RAL for entities',
        path: RoutePath.ral_entity,
        element: <EntityManagerPage />,
      },
      [AppRoutes.RAL_PLATFORM]: {
        title: 'RAL for platforms',
        path: RoutePath.ral_platform,
        element: <PlatformManagerPage />,
      },
      [AppRoutes.HR_LENDING]: {
        title: 'Health ratio for lendings',
        path: RoutePath.hr_lending,
        element: <HRLendingManagerPage />,
      },
    },
  },

  // EXECUTION

  [AppRoutes.EXECUTION]: {
    title: 'Execution',
    path: RoutePath.execution,
    navigate: true,
    icon: <CurrencyExchangeIcon />,
    element: <ExecutionPage />,
    outletPages: {
      [AppRoutes.SMART_CONTRACT_INTERFACE]: {
        title: 'Smart contract interface',
        path: RoutePath.sci,
        element: <SmartContractInterfacePage />,
      },
      [AppRoutes.EXECUTION_SWAP_TOKENS]: {
        title: 'Swap Tokens',
        path: RoutePath.swap_tokens,
        element: <SwapTokensPage />,
      },
      [AppRoutes.EXECUTION_TRADING_INTERFACE]: {
        title: 'Trading interface',
        path: RoutePath.trading_interface,
        element: <TradingInterfacePage />,
      },
    },
  },

  // DETAILED PAGES

  [AppRoutes.TRIGGER]: {
    title: 'Trigger',
    path: RoutePath.trigger,
    element: <TriggerPage />,
  },
  [AppRoutes.VAULT]: {
    title: 'Vault',
    path: RoutePath.vault,
    element: <VaultPage />,
  },

  [AppRoutes.DEFII]: {
    title: 'DeFii',
    path: RoutePath.defii,
    element: <DefiiPage />,
  },
  [AppRoutes.LENDING]: {
    title: 'Lending',
    path: RoutePath.lending,
    element: <LendingPage />,
  },
  [AppRoutes.SWAPS]: {
    title: 'Swap',
    path: RoutePath.swaps,
    element: <SwapPage />,
  },
  [AppRoutes.BRIDGES]: {
    title: 'Bridges',
    path: RoutePath.bridges,
    element: <BridgePage />,
  },
  [AppRoutes.IS_FACTORY]: {
    title: 'Factory',
    path: RoutePath.is_factory,
    element: <FactoryPage />,
  },

  [AppRoutes.ACCOUNT]: {
    title: 'Account',
    path: RoutePath.account,
    element: <AccountPage />,
  },
  [AppRoutes.CLIENT]: {
    title: 'Client',
    path: RoutePath.client,
    element: <ClientPage />,
  },
  [AppRoutes.BLOCKCHAIN]: {
    title: 'Blockchain',
    path: RoutePath.blockchain,
    element: <BlockchainPage />,
  },
  [AppRoutes.UTILITY]: {
    title: 'Utility',
    path: RoutePath.utility,
    element: <UtilityPage />,
  },
  [AppRoutes.ASSET]: {
    title: 'Asset',
    path: RoutePath.asset,
    element: <AssetPage />,
  },
  [AppRoutes.PROTOCOL]: {
    title: 'Protocol',
    path: RoutePath.protocol,
    element: <ProtocolPage />,
  },
  [AppRoutes.PLATFORM]: {
    title: 'Platform',
    path: RoutePath.platform,
    element: <PlatformPage />,
  },
  [AppRoutes.REVENUE_TOKEN]: {
    title: 'Blockchain accounting',
    path: RoutePath.revenue_token,
    element: <RevenueTokenPage />,
  },
  [AppRoutes.ECONOMIC_AUDIT]: {
    title: 'Economic Audit',
    path: RoutePath.economic_audit,
    element: <EconomicAudit />,
  },
  [AppRoutes.ECONOMIC_TRIGGER]: {
    title: 'Economic Trigger',
    path: RoutePath.economic_trigger,
    element: <TriggerPage />,
  },
  [AppRoutes.IT_AUDIT]: {
    title: 'IT Audit',
    path: RoutePath.it_audit,
    element: <ITauditPage />,
  },
  [AppRoutes.SMART_CONTRACTS]: {
    title: 'Smart contract',
    path: `${RoutePath.it_audit}${RoutePath.smart_contracts}`,
    element: <SmartContractAuditPage />,
  },
  [AppRoutes.SMART_CONTRACT_TRIGGER]: {
    title: 'Smart contract trigger',
    path: RoutePath.smart_contract_trigger,
    element: <TriggerPage />,
  },
  [AppRoutes.SMART_CONTRACT_AUDIT_REPORT]: {
    title: 'Smart contract report',
    path: `${RoutePath.it_audit}${RoutePath.smart_contracts}/${AppRoutes.SMART_CONTRACT_AUDIT_REPORT}`,
    element: <SmartContactAuditReportPage />,
  },
  [AppRoutes.WHITELISTING]: {
    title: 'Whitelisting',
    navigate: true,
    icon: <WhitelistingIcon />,
    path: `${RoutePath.whitelisting}`,
    nestedPages: {
      [AppRoutes.WHITELISTING_CONTROL]: {
        title: 'Whitelisting control',
        path: RoutePath.control,
        element: <WhitelistingPage />,
      },
      [AppRoutes.WHITELISTING_VERIFICATION_ADDRESSES]: {
        title: 'Verification addresses',
        path: RoutePath.verification_addresses,
        element: <VerificationAddressesPage />,
      },
    },
  },

  // ***

  [AppRoutes.NOT_FOUND]: {
    title: 'Page not found',
    path: RoutePath.not_found,
    element: <NotFoundPage />,
  },
  [AppRoutes.AUTH]: {
    title: 'Login',
    path: RoutePath.login,
    element: <AuthPage />,
  },
};
