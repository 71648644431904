import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'shared/configs/routes/routeConfig';

export function useNavigateHome() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/') {
      navigate(`${AppRoutes.BUILDING_BLOCKS}/${AppRoutes.BLOCKCHAINS}`);
    }
  }, [pathname, navigate]);
}
