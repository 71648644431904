import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { TRIGGERS_GET_LIST_API_URL, errorHandlerAPI } from 'shared/api';
import { TriggersList } from '../types/triggersListTypes';

interface TriggersResponse {
  triggers: TriggersList
}

export const getTriggers = createAsyncThunk<
TriggersList,
string | undefined,
ThunkConfig<string>
>(
  'trigger/getTriggers',
  async (_, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
      const response = await extra.api.get<TriggersResponse>(TRIGGERS_GET_LIST_API_URL);

      if (!response.data) { throw new Error(); }

      return response.data.triggers;
    } catch (error) {
      return rejectWithValue(errorHandlerAPI(error));
    }
  },
);
