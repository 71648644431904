import { useEffect } from 'react';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../model/services/getUserInfo';
import { getUserData, getUserIsLoading } from '../model/selectors/userSelectors';

export function useUserInfo() {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(getUserIsLoading);
  const user = useSelector(getUserData);

  useEffect(() => {
    dispatch(getUserInfo());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: isLoading && user === undefined,
  };
}
