import { memo } from 'react';
import { motion } from 'framer-motion';
import { blockLoaderVar } from 'shared/configs/animations/blockLoader';
import { Skeleton } from '@mui/material';
import cls from './PageTitle.module.scss';

export const PageTitleLoader = memo(() => (
  <motion.div
    initial="hidden"
    animate="visible"
    className={cls.PageTitle}
    variants={blockLoaderVar}
    style={{ border: 'none' }}
  >
    <Skeleton
      variant="rectangular"
      sx={{
        height: ' var(--title-block-height)',
        borderRadius: 'var(--border-radius-layout)',
      }}
    />
  </motion.div>
));
