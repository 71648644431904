import { TargetAndTransition, Variants } from 'framer-motion';

export const notificationsVar:Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    // transition: {
    //   delayChildren: 0.1,
    //   staggerChildren: 0.1,
    // },
  },
};

export const notificationVar:Variants = {
  hidden: {
    x: '350px',
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.4,
      type: 'spring',
      duration: 1,
      ease: 'easeIn',
    },
  },
};

export const notificationExitVar:TargetAndTransition = {
  x: 50,
  opacity: 0,
  transition: { ease: 'easeIn', duration: 0.3 },
};
