import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { keyGenerate } from 'shared/lib/utils/keyGenerate';
import { NotificationType, NotificationsSchema } from '../types/NotificationsSchema';

const initialState: NotificationsSchema = { notifications: [] };

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addMessage: (state, { payload } : PayloadAction<NotificationType>) => {
      if (payload.title !== undefined) {
        state.notifications = [
          {
            ...payload,
            id: keyGenerate(),
          },
          ...state.notifications,
        ];
      }
    },
    removeMessage: (state, { payload }: PayloadAction<string>) => {
      state.notifications = state.notifications.filter((notification) => notification.id !== payload);
    },
    clearMessages: (state) => {
      state.notifications = [];
    },
  },
});

export const { actions: notificationsActions } = notificationsSlice;
export const { reducer: notificationsReducer } = notificationsSlice;
