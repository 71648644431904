import {
  SmartContactTriggersTypes,
  SmartContractTrigger,
  SmartContractTriggerFormWithType,
  SmartContractTriggersListData,
} from '../model/types/auditTriggerTypes';

interface AuditInfo {
  name: string;
  id: string;
}

const addTriggerType = (
  tr: SmartContractTrigger,
  type: SmartContactTriggersTypes,
  audit?: AuditInfo,
) => ({
  ...tr,
  linkedRedFlags: tr.linkedRedFlags.map((rf) => `${tr.linkedTo}/${rf}`),
  triggerType: type,
  audits: audit !== undefined ? [audit] : undefined,
});

export const mergingTriggers = (
  functionsTriggers?: SmartContractTrigger[],
  smartContractTriggers?: SmartContractTrigger[],
  audit?: AuditInfo,
) => {
  let triggers:SmartContractTriggersListData = [];

  if (smartContractTriggers !== undefined) {
    triggers = [
      ...triggers,
      ...smartContractTriggers
        .map((tr) => addTriggerType(tr, SmartContactTriggersTypes.SMART_CONTRACT, audit)),
    ];
  }

  if (functionsTriggers !== undefined) {
    triggers = [
      ...triggers,
      ...functionsTriggers
        .map((tr) => addTriggerType(tr, SmartContactTriggersTypes.FUNCTION, audit)),
    ];
  }

  const triggersHashmap = triggers
    .reduce((obj, trigger) => {
      if (obj[trigger.id]) {
        obj[trigger.id].linkedRedFlags = [
          ...obj[trigger.id].linkedRedFlags,
          ...trigger.linkedRedFlags,
        ];
        obj[trigger.id].linkedTo = `${obj[trigger.id].linkedTo}, ${trigger.linkedTo}`;
      } else {
        obj[trigger.id] = { ...trigger };
      }

      return obj;
    }, {} as Record<string, SmartContractTriggerFormWithType>);

  return Object.values(triggersHashmap);
};
