import { ReactNode, memo } from 'react';
import { motion } from 'framer-motion';
import { classNames } from 'shared/lib/classNames';
import { titlesVariants } from 'shared/configs/animations/titlesVariants';
import cls from './PageTitle.module.scss';

interface PageTitleProps {
  title?: string;
  className?: string;
  children?: ReactNode;
}

export const PageTitle = memo((props : PageTitleProps) => {
  const { className, title, children } = props;

  return (
    <div className={classNames(cls.PageTitle, {}, [className])}>
      <motion.p
        animate="show"
        initial="hidden"
        variants={titlesVariants}
      >
        {title}
      </motion.p>
      <div>
        {children}
      </div>
    </div>
  );
});
