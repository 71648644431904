import { Suspense } from 'react';
import { motion } from 'framer-motion';
import { Sidebar } from 'widgets/Sidebar';
import { CheckBuildsModal } from 'features/CheckBuildsModal';
import { Notifications } from 'entities/Notifications';
import { AppRouter } from './providers/RouterProvider';

const App = () => (
  <div className="app">
    <Suspense fallback="">
      <CheckBuildsModal />
      <Notifications />
      <Sidebar />
      <motion.div
        className="content"
        initial={{
          opacity: 0,
          scale: 0,
        }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: {
            type: 'spring',
            damping: 16,
          },
        }}
      >
        <div className="content-page">
          <AppRouter />
        </div>
      </motion.div>
    </Suspense>
  </div>
);
export default App;
