import { memo, useMemo } from 'react';
import { useAccount } from 'wagmi';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useAccountModal, useConnectModal, useChainModal } from '@rainbow-me/rainbowkit';
import { BadgeStatus, BadgeStatusProps } from 'shared/ui/BadgeStatus';
import WalletIcon from 'shared/assets/icons/ui/wallet.svg';
import { shortAddress } from 'shared/lib/utils/shortAddress';
import { chainsConfig } from 'shared/configs/wagmi/chainsConfig';
import { TooltipStyles } from '../../config/sidebarTooltipStyle';
import { userInfoVariants } from '../../config/animations';
import { LinkWrapper } from '../LinkWrapper';
import cls from './WalletButton.module.scss';

interface WalletButtonProps { isOpen: boolean }

export const WalletButton = memo((props: WalletButtonProps) => {
  const { isOpen } = props;
  const {
    isConnected, isConnecting, isReconnecting, address, chainId,
  } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();

  const loading:boolean = useMemo(() => isConnecting || isReconnecting, [isConnecting, isReconnecting]);

  const buttonTitle = useMemo(() => {
    if (isConnected) return shortAddress(address);
    if (loading) return 'Connecting';
    return 'Connect wallet';
  }, [address, isConnected, loading]);

  const status:BadgeStatusProps['status'] = useMemo(() => {
    if (isConnected) return 'connected';
    if (loading) return 'waiting';
    return 'disconnected';
  }, [isConnected, loading]);

  const chainIcon = useMemo(
    () => (
      isConnected
        ? chainsConfig.find(((chain) => chain.id === chainId))?.logo
        : <WalletIcon />),
    [chainId, isConnected],
  );

  return (
    <Tooltip
      title={isOpen ? '' : buttonTitle}
      placement="right"
      componentsProps={TooltipStyles}
    >
      <div className={cls.container}>
        <LinkWrapper className={cls.wrapper}>
          <Button
            className={cls.accountIcon}
            onClick={
            // eslint-disable-next-line no-nested-ternary
              isConnected
                ? openChainModal
                : openConnectModal
            }
          >
            {
              loading && (
                <CircularProgress
                  className={cls.loader}
                  size="30"
                />
              )
            }
            <BadgeStatus
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              variant="dot"
              status={status}
            >
              <div className={cls.iconWrapper}>{chainIcon}</div>
            </BadgeStatus>
          </Button>
          {
            isOpen && (
              <AnimatePresence>
                <Button
                  onClick={isConnected ? openAccountModal : openConnectModal}
                  fullWidth
                >
                  { isOpen && (
                    <>
                      <motion.p
                        variants={userInfoVariants}
                        animate="visible"
                        initial="hidden"
                        exit="exit"
                      >
                        {buttonTitle}
                      </motion.p>
                      {isConnected && (
                        <motion.span
                          variants={userInfoVariants}
                          animate="visible"
                          initial="hidden"
                          exit="exit"
                        >
                          <LogoutOutlinedIcon fontSize="small" />
                        </motion.span>
                      )}
                    </>
                  )}
                </Button>
              </AnimatePresence>
            )
          }
        </LinkWrapper>
      </div>
    </Tooltip>

  );
});
