import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { GET_USERS_API_URL, errorHandlerAPI } from 'shared/api';
import { Users } from '../types/UsersSchema';

interface getUsersResponse {users: Users}

export const getUsers = createAsyncThunk<
  getUsersResponse,
  undefined,
  ThunkConfig<string>
>(
  'users/get',
  async (_, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
      const users = await extra.api.get<getUsersResponse>(GET_USERS_API_URL);
      if (!users.data) throw new Error();

      return users.data;
    } catch (error) {
      return rejectWithValue(errorHandlerAPI(error));
    }
  },
);
