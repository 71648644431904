import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { getNotifications } from '../model/selectors/notificationsSelector';
import { notificationsActions } from '../model/slice/notificationsSlice';

const REMOVE_TIME_SEC = 10;

export function useNotification() {
  const dispatch = useAppDispatch();
  const notifications = useSelector(getNotifications);

  const removeNotification = useCallback((id: string) => {
    dispatch(notificationsActions.removeMessage(id));
  }, [dispatch]);

  useEffect(() => {
    let timeout;
    if (notifications && notifications.length) {
      timeout = setTimeout(() => {
        removeNotification(notifications[0].id);
      }, REMOVE_TIME_SEC * 1000);
    } else {
      clearTimeout(timeout);
    }
  }, [notifications, removeNotification]);

  return {
    notifications,
    removeNotification,
  };
}
