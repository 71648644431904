import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { OAUTH_LOGOUT_API_URL, errorHandlerAPI } from 'shared/api';

export const logout = createAsyncThunk<
string,
undefined,
ThunkConfig<string>
>(
  'user/logout',
  async (_, thunkApi) => {
    const {
      extra,
      rejectWithValue,
    } = thunkApi;
    try {
      const response = await extra.api.post<string>(OAUTH_LOGOUT_API_URL);

      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandlerAPI(error));
    }
  },
);
