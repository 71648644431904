export enum NestedPagesParams {
  ENTITIES = 'entities',
  ENTITIES_ID = 'entitiesId',
  ID = 'id',
  NESTED_ID = 'nestedId',
  SECTION = 'section'
}

export const NEW_ID = 'new';

export enum SearchParams {
  ACCOUNT = 'account',
  ADDRESS = 'address',
  CHAIN_FACTORY_ID = 'chainFactoryId',
  CHAIN_ID = 'chainId',
  DEFII = 'defii',
  DEFII_DN = 'defiiDN',
  FACTORY = 'factory',
  LENDING = 'lending',
  TXHASH = 'txHash',
  WALLET = 'wallet'
}
