import { RoutePath } from './routeConfig';

export const tableRoutes:string[] = [
  RoutePath.defiis,
  RoutePath.lendings,
  RoutePath.assets,
  RoutePath.blockchain_accounting,
  RoutePath.blockchains,
  RoutePath.platforms,
  RoutePath.protocols,
  RoutePath.utilities,
  RoutePath.triggers,
  RoutePath.vaults,
  RoutePath.accounts,
  RoutePath.clients,
  RoutePath.execution,
  RoutePath.ral_platform,
  RoutePath.ral_entity,
  RoutePath.hr_lending,
  RoutePath.is_factories,
  RoutePath.decurity_ids,
  RoutePath.swaps_and_bridges,
  `${RoutePath.execution}/${RoutePath.trading_interface}`,
  `${RoutePath.execution}/${RoutePath.swap_tokens}`,
  `${RoutePath.execution}/${RoutePath.sci}`,
];
