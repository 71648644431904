import { BooleanFormField, TextFormField } from 'shared/types/formFields';
import { TriggerSeverity } from './triggersListTypes';

export interface AuditTriggersListItem {
  id: string,
  linkedRedFlags: string[],
  author: string,
  type: TriggerSeverity,
  authorCheckbox: BooleanFormField,
  triggerDeveloper: TextFormField,
}

export interface AuditTriggersListItemForm {
  id: string,
  linkedRedFlags: string[],
  author: string,
  type: TriggerSeverity,
  authorCheckbox: boolean,
  triggerDeveloper: string,
  audits?: {id:string, name: string;}[]
}

export type AuditTriggersList = AuditTriggersListItem[];

export enum SmartContactTriggersTypes {
  FUNCTION = 'function',
  SMART_CONTRACT = 'smartContract'
}
export interface SmartContractTriggerForm extends AuditTriggersListItemForm {
  linkedTo: string;
}
export interface SmartContractTrigger extends AuditTriggersListItem {
  linkedTo: string;
}
export type SmartContractTriggersList = SmartContractTrigger[];

export interface SmartContractTriggerWithType extends SmartContractTriggerForm {
  triggerType: SmartContactTriggersTypes;
}

export interface SmartContractTriggerFormWithType extends SmartContractTrigger {
  triggerType: SmartContactTriggersTypes;
  audits?: {
    name: string;
    id: string;
  }[];
}

export type SmartContractTriggersListData = SmartContractTriggerFormWithType[]
