import { ReactNode, memo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';

const BORDER_RADIUS = 'var(--border-radius-s)';
const FORM_INPUT_FONT_SIZE = '14px';
const INPUT_PADDING = 10;
const INPUT_SMALL_PADDING = 5;

const SMALL_SIZE_PADDING = `${INPUT_SMALL_PADDING + 2}px ${INPUT_SMALL_PADDING}px ${INPUT_SMALL_PADDING}px ${INPUT_SMALL_PADDING * 2}px`;
const FONT_SIZE_SMALL = '12px';

interface MUIthemeProviderProps {
  children?: ReactNode;
}

const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        sx: {
          borderRadius: BORDER_RADIUS,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        sx: {
          padding: '5px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            fontSize: '12px',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiBadge-root': {
            width: '24px',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: '13px',
          maxWidth: 'calc(100% - 45px)',
          top: '3px',
        },
      },
      styleOverrides: {
        shrink: {
          maxWidth: '100% !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            transition: 'border-color var(--animation-duration) ease-in-out',
          },
        },
      },
      defaultProps: {
        sx: {
          fontSize: '13px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
          marginRight: '0px',
          '& .MuiTypography-root': {
            fontSize: FORM_INPUT_FONT_SIZE,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: BORDER_RADIUS,
          '& .MuiOutlinedInput-root': {
            borderRadius: BORDER_RADIUS,
            backgroundColor: '#fff',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          paddingRight: '0px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: '2px',
          marginBottom: '2px',
          fontSize: '0.65rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&&.MuiOutlinedInput-input': {
            padding: INPUT_PADDING,
            paddingRight: INPUT_PADDING / 2,
            fontSize: FORM_INPUT_FONT_SIZE,

            '&.small': {
              minHeight: '20px',
              padding: SMALL_SIZE_PADDING,
              fontSize: FONT_SIZE_SMALL,
            },
          },
          '&&.MuiSelect-select': {
            padding: `${INPUT_PADDING}px 30px ${INPUT_PADDING}px ${INPUT_PADDING}px`,

            '&.small': {
              padding: SMALL_SIZE_PADDING,
              fontSize: FONT_SIZE_SMALL,
              borderRadius: '5px',
            },
          },
          '&&.MuiInputBase-inputMultiline': {
            padding: 0,
          },
        },
        root: {
          '&&.MuiInputBase-multiline:has(> .small)': {
            padding: 0,
            paddingBottom: '1px',
          },

          '&&.MuiInputBase-root:has(> .small)': {
            // padding: 0,
            paddingBottom: '1px',

            button: {
              svg: {
                maxWidth: '18px',
                maxHeight: '18px',
              },
            },
          },

          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              'border-color': 'white',
            },
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          marginTop: 5,
          borderRadius: 'var(--border-radius-s)',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '25px',
          '& .MuiChip-deleteIcon': {
            fontSize: '18px',
            transition: 'color ease-in-out 0.3s',
            '&:hover': {
              color: 'var(--stroke-negative-color)',
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '.MuiFormLabel-root': {
            top: '3px',

            '&.MuiInputLabel-shrink': {
              top: '2px !important',
            },
          },
        },
        inputRoot: {
          minHeight: '41px',
          gap: '5px',

          input: {
            padding: '3px 5px !important',
          },
        },
        paper: {
          marginTop: 5,
          borderRadius: 'var(--border-radius-s)',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: BORDER_RADIUS,
          boxShadow: 'none',
          ':before': {
            display: 'none',
          },
          ':last-of-type': {
            borderRadius: BORDER_RADIUS,
          },
          '& .MuiAccordionSummary-root': {
            minHeight: 30,
            borderRadius: `calc(${BORDER_RADIUS} - 1px)`,

            '&.Mui-expanded': {
              margin: '0px !important',
              minHeight: '40px',
            },

            '& .MuiAccordionSummary-content': {
              margin: '10px 0',
            },

            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: '10px 0',
            },
          },
          '& .MuiAccordionSummary-root .MuiTypography-root': {
            fontSize: FORM_INPUT_FONT_SIZE,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: '10px',
          fontSize: 'var(--tooltip-font-size)',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          borderRadius: BORDER_RADIUS,
        },
      },
    },
  },
});

export const MUIthemeProvider = memo((props : MUIthemeProviderProps) => {
  const { children } = props;

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
});
