import { isAxiosError } from 'axios';
import { isString } from 'lodash';

export const defaultError = 'Something went wrong...';

export function errorHandlerAPI(error: unknown):string {
  if (isAxiosError(error)) {
    const message = error.response?.data?.detail?.errors || error.response?.data?.detail || error.message;
    if (isString(message)) return message;
    return defaultError;
  }
  return (error as Error)?.message || defaultError;
}
