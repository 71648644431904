import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { routeConfig } from 'shared/configs/routes/routeConfig';

export function useDocumentTitle(title?: string) {
  const location = useLocation();

  useEffect(() => {
    const defaultTitle = __APP_TITLE__;
    const separator = ' | ';
    const { pathname } = location;

    if (!title) {
      const pathnames = pathname.split('/');

      const route = Object
        .values(routeConfig)
        .find((item) => item.path === `/${pathnames[1]}`);

      const nestedRoute = route?.nestedPages && Object.values(route.nestedPages).find((route) => `/${pathnames[1]}/${pathnames[2]}` === route.path);

      if (nestedRoute?.title) {
        document.title = `${defaultTitle}${separator}${nestedRoute.title}`;
      } else if (route?.title) {
        document.title = `${defaultTitle}${separator}${route.title}`;
      } else {
        document.title = defaultTitle;
      }
    } else {
      document.title = `${defaultTitle}${separator}${title}`;
    }
  }, [location, title]);
}
