import { ReactNode } from 'react';
import { WagmiProvider } from 'wagmi';
import { getDefaultConfig, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { chainsConfig } from 'shared/configs/wagmi/chainsConfig';

const queryClient = new QueryClient();

const config = getDefaultConfig({
  appName: 'Backoffice',
  projectId: __WALLET_CONNECT_PROJECT_ID__,
  chains: chainsConfig.filter((chain) => !chain.hide) as any,
});

interface OnChainProviderProps { children: ReactNode }

export const OnChainProvider = ({ children } : OnChainProviderProps) => (
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <RainbowKitProvider
        modalSize="compact"
        locale="en-US"
      >
        {children}
      </RainbowKitProvider>
    </QueryClientProvider>
  </WagmiProvider>
);
