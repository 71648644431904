import {
  type CSSProperties, type ReactNode, type RefObject, type WheelEvent, useEffect, useRef, useState, useCallback,
} from 'react';
import { classNames } from 'shared/lib/classNames';
import cls from './ScrollShadowWrapper.module.scss';

export interface ScrollShadowWrapperProps {
  children: ReactNode;
  shadowColor?: string;
  className?: string;
  style?: CSSProperties;
}

export function ScrollShadowWrapper(props: ScrollShadowWrapperProps) {
  const {
    children, shadowColor, className = '', style = {},
  } = props;

  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const onScrollHandler = useCallback((event: WheelEvent<HTMLDivElement>) => {
    setScrollTop(event.currentTarget.scrollTop);
    setScrollHeight(event.currentTarget.scrollHeight);
    setClientHeight(event.currentTarget.clientHeight);
  }, []);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const resetRefSizes = useCallback((ref: RefObject<HTMLDivElement>) => {
    if (!ref.current) return;

    setScrollTop(ref.current.scrollTop);
    setScrollHeight(ref.current.scrollHeight);
    setClientHeight(ref.current.clientHeight);
  }, []);

  useEffect(() => {
    if (!wrapperRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      resetRefSizes(wrapperRef);
    });
    resizeObserver.observe(wrapperRef.current);
    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect();
  }, [resetRefSizes]);

  const getVisibleSides = useCallback((): { top: boolean; bottom: boolean } => {
    const isBottom = clientHeight === scrollHeight - scrollTop;
    const isTop = scrollTop === 0;
    const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;

    return {
      top: (isBottom || isBetween) && !(isTop && isBottom),
      bottom: (isTop || isBetween) && !(isTop && isBottom),
    };
  }, [clientHeight, scrollHeight, scrollTop]);

  return (
    <div
      ref={wrapperRef}
      style={style}
      className={classNames(cls.wrapper, {}, [className])}
      onScroll={onScrollHandler}
    >
      <div
        className={classNames(cls.shadow, {}, [cls.shadowTop])}
        style={{
          opacity: getVisibleSides().top ? 1 : 0,
          background: `linear-gradient(180deg, ${shadowColor || '#00000080'}, transparent)`,
        }}

      />
      {children}
      <div
        className={classNames(cls.shadow, {}, [cls.shadowBottom])}
        style={{
          opacity: getVisibleSides().bottom ? 1 : 0,
          background: `linear-gradient(0deg, ${shadowColor || '#00000080'}, transparent)`,
        }}
      />
    </div>
  );
}

export default ScrollShadowWrapper;
