import Badge, { BadgeProps } from '@mui/material/Badge';
import { useMemo } from 'react';

export interface BadgeStatusProps extends BadgeProps {
  status?: 'connected' | 'waiting' | 'disconnected';
}

export const BadgeStatus = (props: BadgeStatusProps) => {
  const { status, children, ...otherProps } = props;

  const badgeColor = useMemo(() => {
    switch (status) {
    case 'connected':
      return 'var(--icon-positive-color)';

    case 'waiting':
      return 'var(--icon-warning-color)';

    case 'disconnected':
      return 'var(--icon-negative-color)';
    default:
      return 'var(--icon-negative-color)';
    }
  }, [status]);

  return (
    <Badge
      {...otherProps}
      sx={{
        '& .MuiBadge-badge': {
          backgroundColor: badgeColor,
          color: badgeColor,
          zIndex: 2,
          boxShadow: '0 0 0 1px var(--text-contrast-color)',
          '&::after': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: status !== 'disconnected'
              ? 'ripple 1.2s infinite ease-in-out'
              : undefined,
            border: '1px solid currentColor',
            content: status !== 'disconnected' ? '""' : undefined,
          },
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
          },
        },
      }}
    >
      {children}
    </Badge>
  );
};
