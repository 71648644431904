import { AnimatePresence, motion } from 'framer-motion';
import { classNames } from 'shared/lib/classNames';
import { Notification } from 'shared/ui/Notification';
import { notificationExitVar, notificationVar, notificationsVar } from '../lib/animations';
import { useNotification } from '../lib/useNotification';
import cls from './Notifications.module.scss';

interface NotificationsProps {
  className?: string;
}

export const Notifications = (props : NotificationsProps) => {
  const { className } = props;

  const {
    notifications,
    removeNotification,
  } = useNotification();

  return (
    <motion.div
      className={classNames(cls.Notifications, {}, [className])}
      variants={notificationsVar}
      initial="hidden"
      animate="visible"
    >
      <AnimatePresence>
        {
          notifications.map((notification) => (
            <motion.div
              layout
              key={notification.id}
              variants={notificationVar}
              exit={notificationExitVar}
            >
              <Notification
                title={notification.title}
                description={notification.description}
                type={notification.status}
                links={notification.links}
                close={() => removeNotification(notification.id)}
              />
            </motion.div>
          ))
        }
      </AnimatePresence>
    </motion.div>
  );
};
