import { useEffect } from 'react';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from 'shared/configs/routes/routeConfig';
import { useSelector } from 'react-redux';
import { getUsers } from '../model/services/getUsers';
import { getUsersData } from '../model/selectors/usersSelectors';

export function useGetUsers() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const users = useSelector(getUsersData);

  useEffect(() => {
    if (
      users === undefined
      && location.pathname !== `/${AppRoutes.AUTH}`
      && location.pathname !== '/'
    ) {
      dispatch(getUsers());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
}
