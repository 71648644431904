export const TooltipStyles = {
  tooltip: {
    sx: {
      padding: '6px 12px',
      fontSize: '14px',
      color: '#000',
      whiteSpace: 'nowrap',
      background: '#fff',
      borderRadius: 'var(--border-radius-s)',
      boxShadow: '0 5px 10px #0000004d',
    },
  },
};
