import axios from 'axios';
import { RoutePath } from 'shared/configs/routes/routeConfig';
import { keysToCamel } from 'shared/lib/utils/toCase';

export const $api = axios.create({
  baseURL: __BASE_API__,
  withCredentials: true,
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  transformResponse: [(data) => (data ? keysToCamel(JSON.parse(data)) : data)],
});

$api.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        const { origin, pathname } = window.location;
        if (pathname !== `/${RoutePath.login}`) {
          window.location.replace(`${origin}/${RoutePath.login}`);
        }
      }
    }
    return Promise.reject(error);
  },
);
