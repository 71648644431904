import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { SmartContractAuditData } from 'entities/SmartContractAudit';
import { errorHandlerAPI } from 'shared/api';

export const getSmartContractAudit = createAsyncThunk<
  SmartContractAuditData,
  string | undefined,
  ThunkConfig<string>
>(
  'smartContract/getSmartContractAudit',
  async (url, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    if (url === undefined) return rejectWithValue('Smart contract URL is undefined');

    try {
      const response = await extra.api.get<SmartContractAuditData>(url);
      if (!response.data) { throw new Error(); }
      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandlerAPI(error));
    }
  },
);
