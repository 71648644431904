import { createSlice } from '@reduxjs/toolkit';
import { getUsers } from '../services/getUsers';
import { UsersSchema } from '../types/UsersSchema';

const initialState: UsersSchema = { isLoading: true };

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

    // getUsers
      .addCase(getUsers.pending, (state) => {
        state.error = undefined;
        state.data = undefined;
        state.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, { payload: { users } }) => {
        const selects:UsersSchema['selects'] = users.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }));

        state.isLoading = false;
        state.data = users;
        state.selects = selects;
      })
      .addCase(getUsers.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.data = undefined;
        state.error = payload;
      });
  },
});

export const { actions: usersActions } = usersSlice;
export const { reducer: usersReducer } = usersSlice;
