import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { getUserData } from 'entities/User';

interface LoginRouteProps {
  children: ReactNode;
}

export const LoginRoute = (props: LoginRouteProps) => {
  const { children } = props;
  const isAuth = useSelector(getUserData);
  const location = useLocation();

  if (isAuth) {
    return (
      <Navigate
        to="/"
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
};
