import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { enGB } from 'date-fns/locale';
import * as Sentry from '@sentry/react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import App from 'app/App';
import { StoreProvider } from 'app/providers/StoreProvider';
import { ThemeProvider } from 'app/providers/ThemeProvider';
import { MUIthemeProvider } from 'app/providers/MUIthemeProvider';
import { ErrorBoundary } from 'app/providers/ErrorBoundary';
import { HistoryProvider } from 'app/providers/HistoryProvider';
import { OnChainProvider } from 'app/providers/OnChainProvider';
import { SwapDynamicLoadErrorPage } from 'pages/SwapDynamicLoadErrorPage';
import 'shared/configs/schemas/yup';
import 'app/styles/index.scss';

if (!__IS_DEV__) {
  const SENTRY_DSN = __SENTRY_DSN__;

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.5,
    tracePropagationTargets: [__HOST_INGRESS__ || ''],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById('root');

if (!container) {
  throw new Error('Root container is undefined!');
}

const root = createRoot(container);

if (!__IS_DEV__) {
  window.addEventListener('vite:preloadError', () => {
    window.location.reload();
    root.render(<SwapDynamicLoadErrorPage />);
  });
}

root.render(
  <MUIthemeProvider>
    <ErrorBoundary>
      <OnChainProvider>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={enGB}
        >
          <Router>
            <HistoryProvider>
              <StoreProvider>
                <ThemeProvider>
                  <App />
                </ThemeProvider>
              </StoreProvider>
            </HistoryProvider>
          </Router>
        </LocalizationProvider>
      </OnChainProvider>
    </ErrorBoundary>
  </MUIthemeProvider>,
);
