import { Variants } from 'framer-motion';

export const blockLoaderVar:Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 0.3,
    },
  },
};
