import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { errorHandlerAPI } from 'shared/api';

export const deleteSmartContact = createAsyncThunk<
  null,
  string,
  ThunkConfig<string>
>(
  'smartContract/delete',
  async (url, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
      await extra.api.delete<null>(url);

      return null;
    } catch (error) {
      return rejectWithValue(errorHandlerAPI(error));
    }
  },
);
