import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary } from '@mui/material';
import { classNames } from 'shared/lib/classNames';
import cls from './Sidebar.module.scss';

interface NestedLinksSummaryProps {
  path: string;
  icon?: JSX.Element;
  title: string;
}

export const NestedLinksSummary = memo((props : NestedLinksSummaryProps) => {
  const { path, icon, title } = props;
  const { pathname } = useLocation();

  return (
    <AccordionSummary
      className={classNames(
        cls.link,
        { [cls.activeLink]: pathname.includes(path) },
        [cls.nestedLinks],
      )}
      expandIcon={<ExpandMoreIcon />}
    >
      {icon !== undefined && (
        <div className={cls.linkIcon}>
          {icon}
        </div>
      )}
      <span className={cls.linkName}>
        {title}
      </span>
    </AccordionSummary>
  );
});
