import { ReactElement } from 'react';
import {
  Chain, arbitrum, avalanche, bsc, mainnet, optimism, polygon, base,
  boba, evmos, tron, moonbeam, kava, celo, canto, scroll, aurora,
  zksync, fantom, polygonZkEvm,
} from 'viem/chains';
import MainnetLogo from '../../assets/icons/chains/ethereum.svg';
import ArbitrumLogo from '../../assets/icons/chains/arbitrum.svg';
import AvalancheLogo from '../../assets/icons/chains/avalanche.svg';
import OptimismLogo from '../../assets/icons/chains/optimism.svg';
import BaseLogo from '../../assets/icons/chains/base.svg';
import PolygonLogo from '../../assets/icons/chains/polygon.svg';
import BNBLogo from '../../assets/icons/chains/bnb.svg';

import BobaLogo from '../../assets/icons/chains/boba.svg';
import EvmosLogo from '../../assets/icons/chains/evmos.svg';
import TronLogo from '../../assets/icons/chains/tron.svg';
import MoonbeamLogo from '../../assets/icons/chains/moonbeam.svg';
import KavaLogo from '../../assets/icons/chains/kava.svg';
import CeloLogo from '../../assets/icons/chains/celo.svg';
import CantoLogo from '../../assets/icons/chains/canto.svg';
import ScrollLogo from '../../assets/icons/chains/scroll.svg';
import AuroraLogo from '../../assets/icons/chains/aurora.svg';
import ZksyncLogo from '../../assets/icons/chains/zksync.svg';
import SolanaLogo from '../../assets/icons/chains/solana.svg';
import FantomLogo from '../../assets/icons/chains/fantom.svg';

export type ChainConfigItem = Partial<Chain> & {
  names?: string[],
  logo?: ReactElement,
  strokeColor?: string;
  backgroundColor?: string;
  hide?: boolean;
}
export type ChainConfigType = ChainConfigItem[]

export const chainsConfig:ChainConfigType = [
  {
    ...arbitrum,

    // for fork tests

    // rpcUrls: {
    //   ...arbitrum.rpcUrls,
    //   default: {
    //     http: ['http://127.0.0.1:8545'],
    //   },
    //   public: {
    //     http: ['http://127.0.0.1:8545'],
    //   },
    // },

    logo: <ArbitrumLogo />,
    strokeColor: '#203147',
    backgroundColor: '#acd4f0',
    names: ['arbitrum'],
  },
  {
    ...avalanche,
    logo: <AvalancheLogo />,
    strokeColor: '#ae1800',
    backgroundColor: '#f9cccc',
    names: ['avalanche'],
  },
  {
    ...bsc,
    logo: <BNBLogo />,
    strokeColor: '#947000',
    backgroundColor: '#fbe8ad',
    names: ['bnb'],
  },
  {
    ...mainnet,
    logo: <MainnetLogo />,
    strokeColor: '#5c6bc0',
    backgroundColor: '#ccd1eb',
    rpcUrls: {
      ...mainnet.rpcUrls,
      default: {
        http: ['https://eth.llamarpc.com'],
      },
      public: {
        http: ['https://eth.llamarpc.com'],
      },
    },
    names: ['ethereum', 'mainnet'],
  },
  {
    ...optimism,
    logo: <OptimismLogo />,
    strokeColor: '#ae1800',
    backgroundColor: '#febfc0',
    rpcUrls: {
      ...optimism.rpcUrls,
      default: {
        http: ['https://optimism.publicnode.com'],
      },
      public: {
        http: ['https://optimism.publicnode.com'],
      },
    },
    names: ['optimism'],
  },
  {
    ...polygon,
    logo: <PolygonLogo />,
    strokeColor: '#6c00f6',
    backgroundColor: '#d2afff',
    names: ['polygon'],
  },
  {
    ...base,
    logo: <BaseLogo />,
    strokeColor: '#1759f7',
    backgroundColor: '#b8ccfd',
    names: ['base'],
  },

  // hidden chains

  {
    ...boba,
    logo: <BobaLogo />,
    strokeColor: '#3c4b08',
    backgroundColor: '#e9ff95',
    hide: true,
    names: ['boba'],
  },
  {
    ...evmos,
    logo: <EvmosLogo />,
    strokeColor: '#fe5f05',
    backgroundColor: '#e5bcff',
    hide: true,
    names: ['evmos'],
  },
  {
    ...tron,
    logo: <TronLogo />,
    strokeColor: '#EB0029',
    backgroundColor: '#ffdde3',
    hide: true,
    names: ['tron'],
  },
  {
    ...moonbeam,
    logo: <MoonbeamLogo />,
    strokeColor: '#2B1D3C',
    backgroundColor: '#958FDC',
    hide: true,
    names: ['moonbeam'],
  },
  {
    ...kava,
    logo: <KavaLogo />,
    strokeColor: '#FF433E',
    backgroundColor: '#ffc9c8',
    hide: true,
    names: ['kava'],
  },
  {
    ...celo,
    logo: <CeloLogo />,
    strokeColor: '#000',
    backgroundColor: '#fcff52',
    hide: true,
    names: ['celo'],
  },
  {
    ...canto,
    logo: <CantoLogo />,
    strokeColor: '#000',
    backgroundColor: '#06fc99',
    hide: true,
    names: ['canto'],
  },
  {
    ...scroll,
    logo: <ScrollLogo />,
    strokeColor: '#000',
    backgroundColor: '#eecca2',
    hide: true,
    names: ['scroll'],
  },
  {
    ...aurora,
    logo: <AuroraLogo />,
    strokeColor: '#000',
    backgroundColor: '#6ee4e5',
    hide: true,
    names: ['aurora'],
  },
  {
    ...zksync,
    logo: <ZksyncLogo />,
    strokeColor: '#1755f4',
    backgroundColor: '#c7d6fc',
    hide: true,
    names: ['zksync'],
  },
  {
    ...fantom,
    logo: <FantomLogo />,
    strokeColor: '#1969FF',
    backgroundColor: '#bfd6ff',
    hide: true,
    names: ['fantom'],
  },
  {
    ...polygonZkEvm,
    logo: <PolygonLogo />,
    strokeColor: '#6c00f6',
    backgroundColor: '#d2afff',
    hide: true,
    names: ['polygon zkevm'],
  },

  {
    logo: <SolanaLogo />,
    strokeColor: '#9945FF',
    backgroundColor: '#c2fbe3',
    id: 0,
    hide: true,
    names: ['solana'],
  },
];
