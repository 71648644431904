import { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { classNames } from 'shared/lib/classNames';
import cls from './Sidebar.module.scss';

interface NavigateLinkProps {
  path: string;
  title: string;
  className?: string;
  icon?: JSX.Element;
}

export const NavigateLink = memo((props : NavigateLinkProps) => {
  const {
    path, icon, title, className,
  } = props;
  const { pathname } = useLocation();

  return (
    <Link
      to={path}
      className={classNames(
        cls.link,
        { [cls.activeLink]: pathname.includes(path) },
        [className],
      )}
    >
      {icon !== undefined && (
        <div className={cls.linkIcon}>
          {icon}
        </div>
      )}
      <span className={cls.linkName}>
        {title}
      </span>
    </Link>
  );
});
