import { memo } from 'react';
import { WalletButton } from './WalletButton/WalletButton';
import { UserInfo } from './UserInfo/UserInfo';
import cls from './Sidebar.module.scss';

interface FooterProps { isOpen: boolean }

export const Footer = memo((props : FooterProps) => {
  const { isOpen } = props;

  return (
    <div className={cls.sidebarFooter}>
      <WalletButton isOpen={isOpen} />
      <UserInfo showName={isOpen} />
    </div>
  );
});
