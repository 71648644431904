/* eslint-disable func-names */
import { isAddress } from 'viem';
import {
  addMethod, array, string,
} from 'yup';

declare module 'yup' {
  interface StringSchema {
    isAddress(): StringSchema;
  }
  // @ts-ignore
  interface ArraySchema<T> {
    // @ts-ignore
    unique(mapper: (a: T) => T, message?: any): ArraySchema<T>;
    // @ts-ignore
    allPositiveAndInteger(mapper: (a: T) => T, message?: any): ArraySchema<T>;
  }
}

addMethod(string, 'isAddress', function (errorMessage) {
  return this.test('is-address', errorMessage, function (value) {
    const { path, createError } = this;

    if (!value || value.trim() === '') return true;

    return isAddress(value) || createError({ path, message: errorMessage || 'Address is invalid' });
  });
});

addMethod(array, 'unique', function (
  mapper = (a: any) => a,
  // eslint-disable-next-line no-template-curly-in-string
  message: string = '${path} may not have duplicates',
) {
  return this.test('unique', message, (list:any) => {
    if (Array.isArray(list) && list.length > 0) {
      return list?.length === new Set(list.map(mapper)).size;
    }
    return true;
  });
});

addMethod(array, 'allPositiveAndInteger', function (
  mapper = (a: any) => a,
  // eslint-disable-next-line no-template-curly-in-string
  message: string = '${path} may have positive and integer',
) {
  return this.test('allPositiveAndInteger', message, (list:any[]) => {
    if (Array.isArray(list) && list.length > 0) {
      return list?.map(mapper)
        ?.every((i: string | number) => Number(i) > 0 && Number.isInteger(i));
    }
    return true;
  });
});

export function emptyStringToNull(value:string, originalValue:string) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}
