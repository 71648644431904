/* eslint-disable react/jsx-indent */
import { ReactNode, memo } from 'react';
import { Variants, motion } from 'framer-motion';

export const LinkWrapper = memo(({
  children,
  variants,
  className,
  ...otherProps
} : {
    children?: ReactNode,
    variants?: Variants,
    className?: string,
    otherProps?:HTMLDivElement,
}) => (
  <motion.div
    {...otherProps}
    variants={variants}
    className={className}
    whileHover={{
      scale: 1.02,
      transition: { duration: 0.3 },
    }}
    whileTap={{ scale: 0.99 }}
  >
    {children}
  </motion.div>
));
