import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { GET_USER_LOGIN_API_URL, errorHandlerAPI } from 'shared/api';
import { CurrentUser } from '../types/UserSchema';

export const getUserInfo = createAsyncThunk<
  CurrentUser,
  undefined,
  ThunkConfig<string| undefined>
>(
  'user/get',
  async (_, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
      const login = await extra.api.get<CurrentUser>(GET_USER_LOGIN_API_URL);
      if (!login.data) throw new Error();

      return login.data;
    } catch (error) {
      const knownError = error as AxiosError;
      if (knownError.response?.status !== 401) return rejectWithValue(errorHandlerAPI(error));
      return rejectWithValue(undefined);
    }
  },
);
