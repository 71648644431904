/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';
import { SmartContractTriggerFormWithType, mergingTriggers } from 'entities/Trigger';

export const getSmartContractAuditIsLoading = (state:StateSchema) => state?.smartContractAudit?.isLoading;
export const getSmartContractAuditError = (state:StateSchema) => state?.smartContractAudit?.error;
export const getSmartContractAuditData = (state:StateSchema) => state?.smartContractAudit?.data;
export const getSmartContractAuditRedFlags = (state:StateSchema) => state?.smartContractAudit?.data?.redFlags;
export const getSmartContractAuditFunctions = (state:StateSchema) => state?.smartContractAudit?.data?.functions;

const getSmartContractAuditFuncTriggers = (state:StateSchema) => state?.smartContractAudit?.data?.functionsTriggers;
const getSmartContractAuditScTriggers = (state:StateSchema) => state?.smartContractAudit?.data?.smartContractTriggers;

export const getSmartContractAuditTriggers = createSelector(
  [getSmartContractAuditFuncTriggers, getSmartContractAuditScTriggers],
  (functionsTriggers, smartContractTriggers):SmartContractTriggerFormWithType[] => mergingTriggers(functionsTriggers, smartContractTriggers),
);

export const getSmartContractAuditCanDelete = (state:StateSchema) => state?.smartContractAudit?.data?.canDelete;

export const getSmartContractAuditFormData = createSelector(
  [getSmartContractAuditData],
  (data) => {
    if (data !== undefined) {
      const {
        redFlags,
        functions,
        functionsTriggers,
        smartContractTriggers,
        ...formData
      } = data;

      return formData;
    }
    return undefined;
  },
);
