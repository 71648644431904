import {
  Suspense, memo, useCallback, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { TableBlockLoader } from 'widgets/TableBlock';
import {
  getIsAuth, getUserError, useUserInfo,
} from 'entities/User';
import { useGetUsers } from 'entities/Users';
import { useDocumentTitle } from 'shared/lib/hooks/useDocumentTitle';
import {
  AppRoutes, AppRoutesProps, routeConfig,
} from 'shared/configs/routes/routeConfig';
import { LoaderPage } from 'shared/ui/LoaderPage';
import { tableRoutes } from 'shared/configs/routes/tableRoutes';
import { ScrollToTop } from './ScrollToTop';
import { useNavigateHome } from '../hooks/useNavigateHome';
import { LoginRoute } from './LoginRoute';

const RouteLoader = (({ isTableBlock }: {isTableBlock: boolean}) => (isTableBlock ? <TableBlockLoader /> : <LoaderPage />));

const AppRouter = () => {
  const error = useSelector(getUserError);
  const isAuth = useSelector(getIsAuth);
  const { pathname } = useLocation();
  const { isLoading } = useUserInfo();
  useGetUsers();
  useDocumentTitle();
  useNavigateHome();

  const isTableBlock = useMemo(() => tableRoutes.some((route) => route === pathname), [pathname]);

  const renderWithWrapper = useCallback(({
    path, element, nestedPages, outletPages,
  }: AppRoutesProps) => {
    if (path === AppRoutes.AUTH) {
      return (
        <Route
          key={path}
          element={<LoginRoute>{element}</LoginRoute>}
          path={path}
        />
      );
    }
    if (isAuth) {
      if (nestedPages !== undefined) {
        return Object.values(nestedPages).map((({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={element}
          />
        )));
      }
      if (outletPages !== undefined) {
        return (
          <Route
            key={path}
            path={path}
            element={element}
          >
            {
              Object.values(outletPages).map((({ path, element }) => (
                <Route
                  key={path}
                  path={path}
                  element={element}
                />
              )))
            }
          </Route>
        );
      }
      return (
        <Route
          key={path}
          path={path}
          element={element}
        />
      );
    }
    return null;
  }, [isAuth]);

  if (error) throw new Error();

  return (
    <Suspense fallback={<RouteLoader isTableBlock={isTableBlock} />}>
      <ScrollToTop>
        {
          isLoading
            ? (<LoaderPage />)
            : (<Routes>{Object.values(routeConfig).map(renderWithWrapper)}</Routes>)
        }
      </ScrollToTop>
    </Suspense>
  );
};

export default memo(AppRouter);
