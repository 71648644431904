import {
  ReactNode, memo, useCallback, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Avatar, IconButton, Tooltip } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { motion, AnimatePresence } from 'framer-motion';
import { getGravatarURL } from 'entities/User/utils/getGravatarURL';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { logout, getUserEmail, getUserFullName } from 'entities/User';
import { avatarVariants, userInfoVariants, wrapperUserInfoVariants } from '../../config/animations';
import { TooltipStyles } from '../../config/sidebarTooltipStyle';
import cls from './UserInfo.module.scss';

const UserIconWrapper = ({ children, name }: {children: ReactNode, name: string}) => (
  <motion.div
    initial="hidden"
    animate="visible"
    exit="exit"
    key={name}
    variants={avatarVariants}
  >
    {children}
  </motion.div>
);

interface UserInfoProps { showName: boolean; }

export const UserInfo = memo((props: UserInfoProps) => {
  const { showName } = props;

  const dispatch = useAppDispatch();
  const userFullName = useSelector(getUserFullName);
  const userEmail = useSelector(getUserEmail);
  const avatarSrc = getGravatarURL(userEmail);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget), []);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const userLogout = useCallback(() => dispatch(logout()), [dispatch]);

  return (
    <div className={cls.container}>
      <div className={cls.userBlock}>
        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {
            !showName && (
              <MenuItem disabled>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <p className={cls.name}>
                  {userFullName}
                </p>
              </MenuItem>
            )
          }
          <MenuItem onClick={userLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
        <Tooltip
          title={showName ? '' : userFullName}
          placement="right"
          componentsProps={TooltipStyles}
        >
          <IconButton
            onClick={handleClick}
            className={cls.userButton}
          >
            <AnimatePresence>
              <UserIconWrapper name="icon">
                <Avatar
                  sx={{
                    width: '40px',
                    height: '40px',
                    bgcolor: '#fff',
                  }}
                  src={avatarSrc}
                />
              </UserIconWrapper>
            </AnimatePresence>
          </IconButton>
        </Tooltip>
        <AnimatePresence>
          {
            showName && (
              <motion.div
                variants={wrapperUserInfoVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <motion.p
                  className={cls.name}
                  variants={userInfoVariants}
                >
                  {userFullName}
                </motion.p>
                <motion.p
                  className={cls.email}
                  variants={userInfoVariants}
                >
                  {userEmail}
                </motion.p>
              </motion.div>
            )
          }
        </AnimatePresence>
      </div>
    </div>
  );
});
