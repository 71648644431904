import {
  memo,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IconButton, Tooltip } from '@mui/material';
import { getIsAuth } from 'entities/User';
import { Mods, classNames } from 'shared/lib/classNames';
import { routeConfig } from 'shared/configs/routes/routeConfig';
import { LOCAL_STORAGE_KEYS } from 'shared/const/localStorage';
import { ScrollShadowWrapper } from 'shared/ui/ScrollShadowWrapper';
import { NestedLinks } from './NestedLinks';
import { LinkWrapper } from './LinkWrapper';
import { TooltipStyles } from '../config/sidebarTooltipStyle';
import { NavigateLink } from './NavigateLink';
import { Header } from './Header';
import { Footer } from './Footer';
import cls from './Sidebar.module.scss';

export const Sidebar = memo(() => {
  const isAuth = useSelector(getIsAuth);

  const [isOpen, setIsOpen] = useState<boolean>(() => {
    const value = localStorage.getItem(LOCAL_STORAGE_KEYS.SIDEBAR_IS_OPEN);
    if (value !== null) {
      return Boolean(JSON.parse(value));
    }
    return false;
  });

  const sidebarToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
    localStorage.setItem(LOCAL_STORAGE_KEYS.SIDEBAR_IS_OPEN, JSON.stringify(!isOpen));
  }, [isOpen]);

  const sidebarMods:Mods = useMemo(() => ({ [cls.isOpen]: isOpen }), [isOpen]);

  const returnLink = useCallback(() => (
    Object.values(routeConfig)
      .filter(({ navigate }) => navigate)
      .map((route) => {
        const {
          navigate, nestedPages, outletPages, title, path, icon,
        } = route;

        if (navigate) {
          if (nestedPages === undefined && outletPages === undefined) {
            return (
              <Tooltip
                title={isOpen ? '' : title}
                placement="right"
                componentsProps={TooltipStyles}
                key={path}
              >
                <div className={cls.navigationItem}>
                  <LinkWrapper>
                    <NavigateLink
                      path={path}
                      icon={icon}
                      title={title}
                    />
                  </LinkWrapper>
                </div>
              </Tooltip>
            );
          }
          if (nestedPages !== undefined) {
            return (
              <NestedLinks
                icon={icon}
                path={path}
                title={title}
                sidebarOpen={isOpen}
                key={`${path}-${title}`}
                openSidebar={() => setIsOpen(true)}
                nestedLinks={Object.values(nestedPages)}
              />
            );
          }
          if (outletPages !== undefined) {
            return (
              <NestedLinks
                icon={icon}
                path={path}
                title={title}
                isOutletLink
                sidebarOpen={isOpen}
                key={`${path}-${title}`}
                openSidebar={() => setIsOpen(true)}
                nestedLinks={Object.values(outletPages)}
              />
            );
          }
        }
        return null;
      })
  ), [isOpen]);

  if (isAuth) {
    return (
      <motion.div
        className={classNames(cls.Sidebar, sidebarMods)}
        animate={{
          opacity: 1,
          x: 0,
          width: isOpen ? '270px' : '60px',
          transition: {
            type: 'spring',
            damping: 16,
            x: { duration: 0.5 },
          },
        }}
        initial="hidden"
        variants={{
          hidden: {
            x: -50,
            width: '55px',
            opacity: 0,
          },
        }}
      >
        <Header />
        <motion.div
          className={cls.sidebarToggle}
          whileHover={{
            scale: 1.1,
            transition: { duration: 0.3 },
          }}
        >
          <IconButton
            size="small"
            onClick={sidebarToggle}
          >
            {
              isOpen
                ? <ChevronLeftIcon fontSize="inherit" />
                : <ChevronRightIcon fontSize="inherit" />
            }
          </IconButton>
        </motion.div>
        <ScrollShadowWrapper
          className={cls.navList}
          shadowColor="#2044a1"
        >
          {returnLink()}
        </ScrollShadowWrapper>
        <Footer isOpen={isOpen} />
      </motion.div>
    );
  }

  return null;
});
