import { isObject } from '../isObject';

const toCamel = (str: string) => str.replace(/([_][a-z])/ig, ($1) => $1.toUpperCase()
  .replace('_', ''));

export const toSnake = (string: string) => string.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export function keysToCamel(obj: any):any {
  if (isObject(obj)) {
    const n:Record<string, any> = {};

    Object.keys(obj as Record<string, any>)
      .forEach((k) => {
        n[toCamel(k)] = keysToCamel(obj[k]);
      });

    return n;
  } if (Array.isArray(obj)) {
    return obj.map((i) => keysToCamel(i));
  }

  return obj;
}

export function keysToSnake(obj: any):any {
  if (isObject(obj)) {
    const n:Record<string, any> = {};

    Object.keys(obj as Record<string, any>)
      .forEach((k) => {
        n[toSnake(k)] = keysToSnake(obj[k]);
      });

    return n;
  } if (Array.isArray(obj)) {
    return obj.map((i) => keysToSnake(i));
  }

  return obj;
}
