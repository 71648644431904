import { ReactNode, memo } from 'react';
import { motion } from 'framer-motion';
import { classNames } from 'shared/lib/classNames';
import { PageTitle } from 'shared/ui/PageTitle';
import cls from './TableBlock.module.scss';

interface TableBlockProps {
  className?: string;
  children?: ReactNode;
  title?: string;
  double?: boolean;
}

export const TableBlock = memo((props : TableBlockProps) => {
  const {
    className, children, title, double,
  } = props;
  return (
    <div className={classNames(cls.TableBlock, {}, [className])}>
      <PageTitle title={title} />
      <motion.div className={classNames(cls.details, { [cls.double]: double })}>
        {children}
      </motion.div>
    </div>
  );
});
