import { useEffect, useMemo, useState } from 'react';

export function useCheckBuilds(delay: number) {
  const isLocalhost = useMemo(() => window.origin.includes('localhost'), []);
  const [isOutdatedBuild, setIsOutdatedBuild] = useState(false);

  useEffect(() => {
    const checkSourceUpdated = async () => {
      let localSource = [
        ...document.querySelectorAll('script'),
      ].map((script) => script.src);

      const parser = new DOMParser();
      const r = await fetch(
        window.location.href,
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      );
      const htmlDoc = parser.parseFromString(await r.text(), 'text/html');

      let currentSource = [...htmlDoc.querySelectorAll('script')].map((script) => script.src);

      localSource = localSource.filter((source) => source.includes('index'));
      currentSource = currentSource.filter((source) => source.includes('index'));

      if (
        localSource[0] !== undefined && currentSource[0] !== undefined
        && localSource[0] !== currentSource[0]
      ) setIsOutdatedBuild(true);
      else setIsOutdatedBuild(false);
    };

    const interval = setInterval(() => { if (!isLocalhost) checkSourceUpdated(); }, delay);
    return () => clearInterval(interval);
  }, [delay, isLocalhost]);

  return { isOutdatedBuild };
}
