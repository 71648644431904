import { createSlice } from '@reduxjs/toolkit';
import { AppRoutes } from 'shared/configs/routes/routeConfig';
import { UserSchema } from '../types/UserSchema';
import { getUserInfo } from '../services/getUserInfo';
import { logout } from '../services/logout';

const initialState: UserSchema = {
  isLoading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

    // getUserInfo
      .addCase(getUserInfo.pending, (state) => {
        state.error = undefined;
        state.data = undefined;
        state.isLoading = true;
      })
      .addCase(getUserInfo.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      })
      .addCase(getUserInfo.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.data = undefined;
        state.error = payload;
      })

      // logout
      .addCase(logout.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.data = undefined;
        window.location.replace(`${window.location.origin}/${AppRoutes.AUTH}`);
      })
      .addCase(logout.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
        state.data = undefined;
      });
  },
});

export const { actions: userActions } = userSlice;
export const { reducer: userReducer } = userSlice;
