import { StateSchema } from 'app/providers/StoreProvider';

export const getUserData = (state:StateSchema) => state.user.data;
export const getIsAuth = (state:StateSchema) => !!state.user.data;

export const getUserFullName = (state: StateSchema) => {
  const firstname = state.user.data?.firstName;
  const lastname = state.user.data?.lastName;

  return `${firstname} ${lastname}`;
};
export const getUserEmail = (state:StateSchema) => state.user.data?.email;
export const getUserError = (state:StateSchema) => state.user.error;
export const getUserIsLoading = (state:StateSchema) => state.user.isLoading;
