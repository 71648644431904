import {
  memo, useCallback, useEffect, useState,
} from 'react';
import { Button } from '@mui/material';
import { Variants, motion } from 'framer-motion';
import CachedIcon from '@mui/icons-material/Cached';
import UpdateImg from 'shared/assets/images/update.png';
import { Modal } from 'shared/ui/Modal';
import { useCheckBuilds } from 'shared/lib/hooks/useCheckBuilds';
import cls from './CheckBuildsModal.module.scss';

export const items:Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.3,
      duration: 1,
    },
  },
};

export const item:Variants = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export const CheckBuildsModal = memo(() => {
  const { isOutdatedBuild } = useCheckBuilds(5 * 60 * 1000);
  const [openModal, setOpenModal] = useState(isOutdatedBuild);
  const onClose = useCallback(() => setOpenModal(false), []);

  useEffect(() => { if (isOutdatedBuild) setOpenModal(isOutdatedBuild); }, [isOutdatedBuild]);

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      header="Notice"
      footer={(
        <Button
          variant="contained"
          onClick={() => window.location.reload()}
          color="primary"
          endIcon={<CachedIcon fontSize="medium" />}
          className={cls.reloadBtn}
          fullWidth
        >
          Reload page
        </Button>
      )}
    >
      <motion.div
        className={cls.CheckBuildsModal}
        variants={items}
        initial="hidden"
        animate="visible"
      >
        <motion.img
          src={UpdateImg}
          variants={item}
        />
        <motion.p variants={item}>
          This application version is outdated
        </motion.p>
      </motion.div>
    </Modal>
  );
});
